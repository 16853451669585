<template>
	<div
		class="flex h-[var(--height)] w-[var(--width)] flex-row flex-nowrap justify-between rounded-full border border-zinc-500"
		:style="cssProps"
	>
		<button
			:tabindex="0"
			class="!h-full w-full pl-1 leading-none focus-visible:outline focus-visible:outline-cta"
			@click="emit('update:decrement')"
		>
			-
		</button>
		<input
			type="number"
			min="1"
			step="1"
			:value="value"
			:style="cssProps"
			:aria-label="aria"
			:class="[
				'no-spinner w-[30px] border-none text-center text-[length:var(--font-size-mobile)] md:text-[length:var(--font-size)]',
				'focus-visible:outline focus-visible:outline-cta',
			]"
			@input="emit('update:value', +($event.target as HTMLInputElement).value)"
		/>
		<button
			:tabindex="0"
			class="w-full pr-1 leading-none focus-visible:outline focus-visible:outline-cta"
			@click="emit('update:increment')"
		>
			+
		</button>
	</div>
</template>

<script setup lang="ts">
interface Props {
	value: number;
	width?: string;
	height?: string;
	minQuantity?: number;
	fontSize?: string;
	fontSizeMobile?: string;
	aria: string;
}

const props = withDefaults(defineProps<Props>(), {
	value: 1,
	width: '80px',
	height: '28px',
	minQuantity: 1,
	fontSize: '1rem',
	fontSizeMobile: '1rem',
});

const emit = defineEmits<{
	(e: 'update:increment' | 'update:decrement'): void;
	(e: 'update:value', value: number);
}>();

const cssProps = computed(() => {
	return {
		'--width': props.width,
		'--height': props.height,
		'--font-size': props.fontSize,
		'--font-size-mobile': props.fontSizeMobile,
	};
});
</script>
